import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createBlock as _createBlock } from "vue";
import _imports_0 from '@/assets/images/home_a.png';
import _imports_1 from '@/assets/images/home.png';
import _imports_2 from '@/assets/images/liquidity_a.png';
import _imports_3 from '@/assets/images/liquidity.png';
import _imports_4 from '@/assets/images/me_a.png';
import _imports_5 from '@/assets/images/me.png';
const _hoisted_1 = {
  key: 0,
  src: _imports_0
};
const _hoisted_2 = {
  key: 1,
  src: _imports_1
};
const _hoisted_3 = {
  key: 0,
  src: _imports_2
};
const _hoisted_4 = {
  key: 1,
  src: _imports_3
};
const _hoisted_5 = {
  key: 0,
  src: _imports_4
};
const _hoisted_6 = {
  key: 1,
  src: _imports_5
};
import { ref } from 'vue';
export default {
  __name: 'Tabbar',
  props: ['active'],
  setup(__props) {
    const props = __props;
    const active = ref(props.active);
    return (_ctx, _cache) => {
      const _component_van_tabbar_item = _resolveComponent("van-tabbar-item");
      const _component_van_tabbar = _resolveComponent("van-tabbar");
      return _openBlock(), _createBlock(_component_van_tabbar, {
        modelValue: active.value,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => active.value = $event),
        "inactive-color": "#92949D",
        "active-color": "#333333",
        fixed: "",
        placeholder: ""
      }, {
        default: _withCtx(() => [_createVNode(_component_van_tabbar_item, {
          to: "/home"
        }, {
          icon: _withCtx(props => [props.active ? (_openBlock(), _createElementBlock("img", _hoisted_1)) : (_openBlock(), _createElementBlock("img", _hoisted_2))]),
          default: _withCtx(() => [_createElementVNode("span", null, _toDisplayString(_ctx.$t('home')), 1)]),
          _: 1
        }), _createVNode(_component_van_tabbar_item, {
          to: "/liquidity"
        }, {
          icon: _withCtx(props => [props.active ? (_openBlock(), _createElementBlock("img", _hoisted_3)) : (_openBlock(), _createElementBlock("img", _hoisted_4))]),
          default: _withCtx(() => [_createElementVNode("span", null, _toDisplayString(_ctx.$t('liquidity_pool')), 1)]),
          _: 1
        }), _createVNode(_component_van_tabbar_item, {
          to: "me"
        }, {
          icon: _withCtx(props => [props.active ? (_openBlock(), _createElementBlock("img", _hoisted_5)) : (_openBlock(), _createElementBlock("img", _hoisted_6))]),
          default: _withCtx(() => [_createElementVNode("span", null, _toDisplayString(_ctx.$t('me')), 1)]),
          _: 1
        })]),
        _: 1
      }, 8, ["modelValue"]);
    };
  }
};